import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'
import Attachment from '@/shared/modules/attachment/attachment.model'
import Checklist from '@/shared/modules/checklist/checklist.model'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IChecklistResponse from '@/shared/modules/checklist/checklist-response.interface'
export default class ChecklistService extends Service {
  static prefix = 'checklists'

  static show(company: Company, uuid: string): Promise<Checklist> {
    return this.get(`company/${ company.slug }/${ this.prefix }/${ uuid }`)
      .then((response: IResponse<IChecklistResponse>) => new Checklist(response.data))
  }

  static downloadAttachment(company: Company, checklist: Checklist, attachment: Attachment): Promise<any> {
    return this.get(`company/${ company.slug }/${ this.prefix }/${ checklist.uuid }/attachments/${ attachment.uuid }/download`, { responseType: 'blob' })
  }

  static report(company: Company, checklistUuids: string[]): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }/report`, { checklist_uuids: checklistUuids }, { responseType: 'blob' })
  }
}

















import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import FormBase from '@/shared/classes/form/form-base'
import Checklist from '@/shared/modules/checklist/checklist.model'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import IChecklistResponse from '@/shared/modules/checklist/checklist-response.interface'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class EditChecklistDialog extends Vue {
  @Prop() meta!: any
  form: FormBase = new FormBase()
    .setUuid(this.meta.checklist.uuid)
    .setModel(Checklist)
    .setMethod(HttpMethod.PUT)
    .setEndpoint(`company/${ this.company.slug }/checklists`)
    .setValidate(true, { onlyRegionLangRequired: true })
    .setTranslatable(true)
    .setFields([
      new Field()
        .setKey('name')
        .setTranslatable(true)
        .setTitle(__('company.components.checklists.edit-checklist-dialog.form.fields.name')),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('description')
        .setTranslatable(true)
        .setTitle(__('company.components.checklists.edit-checklist-dialog.form.fields.description')),
      new SearchableField()
        .setKey('responsible_uuid')
        .setEntryKey('responsibleUuid')
        .setTitle(__('company.components.checklists.edit-checklist-dialog.form.fields.responsible'))
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 }),
    ])
    .setOnSuccess((response: IChecklistResponse, checklist: Checklist) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.checklists.edit-checklist-dialog.form.on-success')
      })

      this.meta.onSuccess && this.meta.onSuccess(checklist)
    })

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}




















import { Component, Prop, Vue } from 'vue-property-decorator'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import CheckpointService from '@/shared/modules/checkpoint/checkpoint.service'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import ObjectField from '@/shared/classes/form/fields/object-field'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import _ from 'lodash'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class FillCheckpointsDialog extends Vue {
  @Prop() meta!: any
  checkpoints: Checkpoint[] = []
  loading: boolean = false
  form: FormBase | null = null

  created() {
    this.loadCheckpoints()
  }

  private loadCheckpoints(): void {
    this.loading = true
    CheckpointService.indexByChecklist(this.company, this.meta.checklist, 9999)
      .then((checkpoints: Checkpoint[]) => {
        this.checkpoints = checkpoints

        const initialValues: any = {}

        this.checkpoints.forEach((item: Checkpoint) => {
          initialValues[item.uuid] = {
            status_ok: item.statusUuid === Checkpoint.STATUS_OK,
            status_violation: item.statusUuid === Checkpoint.STATUS_VIOLATION,
            status_ir: item.statusUuid === Checkpoint.STATUS_IR,
            description: item.description,
            violation: {
              comment: item.violation && item.violation.description || item.description,
              date: item.violation && item.violation.seenAt,
              responsible_uuid: item.violation ? item.violation.responsibleUuid : this.meta.project.responsibleUuid,
              responsible_employee_uuid: item.violation && item.violation.responsibleEmployeeUuid,
              deadline: item.violation && item.violation.deadline,
              location: item.violation && item.violation.location
            }
          }
        })

        this.form = new FormBase()
          .setEndpoint(`company/${ this.company.slug }/checkpoints/fill`)
          .setFiles(true)
          .setFields(this.checkpoints.map(
            (checkpoint: Checkpoint) => new ObjectField()
              .setKey(checkpoint.uuid)
              .setTitle(checkpoint.name as string)
              .setSubtitle(checkpoint.description as string)
              .setClasses('pa-3 mb-3 rounded elevation-3')
              .setChildren([
                new Field()
                  .setType(FieldTypes.button)
                  .setKey('status_ok')
                  .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.status-ok'))
                  .setClasses('mb-2')
                  .setOnChange((value: boolean): void => {
                    if (! this.form || ! value) return
                    _.set(this.form.data, `${ checkpoint.uuid }.status_violation`, false)
                    _.set(this.form.data, `${ checkpoint.uuid }.status_ir`, false)
                  })
                  .setMeta({
                    color: 'primary'
                  })
                  .setSize(FieldSizes.fourTwelfth),
                new Field()
                  .setType(FieldTypes.button)
                  .setKey('status_violation')
                  .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.status-violation'))
                  .setClasses('mb-2')
                  .setOnChange((value: boolean): void => {
                    if (! this.form || ! value) return
                    _.set(this.form.data, `${ checkpoint.uuid }.status_ok`, false)
                    _.set(this.form.data, `${ checkpoint.uuid }.status_ir`, false)
                  })
                  .setMeta({
                    color: 'error'
                  })
                  .setSize(FieldSizes.fourTwelfth),
                new Field()
                  .setType(FieldTypes.button)
                  .setKey('status_ir')
                  .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.status-ir'))
                  .setClasses('mb-2')
                  .setOnChange((value: boolean): void => {
                    if (! this.form || ! value) return
                    _.set(this.form.data, `${ checkpoint.uuid }.status_ok`, false)
                    _.set(this.form.data, `${ checkpoint.uuid }.status_violation`, false)
                  })
                  .setMeta({
                    color: 'secondary'
                  })
                  .setSize(FieldSizes.fourTwelfth),
                new ObjectField()
                  .setKey('violation')
                  .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.violation'))
                  .setDense(true)
                  .setVisibleIf((values: any) => _.get(values, `${ checkpoint.uuid }.status_violation`, false))
                  .setChildren([
                    new Field()
                      .setKey('comment')
                      .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.comment'))
                      .isRequired(),
                    new Field()
                      .setType(FieldTypes.datePicker)
                      .setKey('date')
                      .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.date'))
                      .setSize(FieldSizes.half)
                      .isRequired(),
                    new Field()
                      .setType(FieldTypes.datePicker)
                      .setKey('deadline')
                      .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.deadline'))
                      .setSize(FieldSizes.half),
                    new SearchableField()
                      .setKey('responsible_uuid')
                      .setVisibleIf(() => this.user.role.level !== RoleLevel.employee)
                      .setSize(FieldSizes.half)
                      .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.responsible'))
                      .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 }),
                    new SearchableField()
                      .setKey('responsible_employee_uuid')
                      .setSize(FieldSizes.half)
                      .setVisibleIf(() => this.user.role.level !== RoleLevel.employee)
                      .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.responsible-employee'))
                      .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 }),
                    new Field()
                      .setKey('location')
                      .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.location')),
                  ]),
                new Field()
                  .setType(FieldTypes.multipleFile)
                  .setKey('attachments')
                  .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.attachments'))
                  .setMeta({
                    accept: 'image/*',
                    class: 'mt-4 col',
                  })
                  .setDense(true),
               new Field()
                  .setKey('description')
                  .setTitle(__('company.components.checkpoints.fill-checkpoints-dialog.form.comment'))
                  .setDense(true)
                  .setMeta({
                     class: 'mt-4 col',
                   })
                  .setVisibleIf((values: any) => {
                    if (! values) return
                    const statusOkState = _.get(values, `${ checkpoint.uuid }.status_ok`, false)
                    const statusIrState = _.get(values, `${ checkpoint.uuid }.status_ir`, false)

                    return statusOkState || statusIrState
                  })
              ])
          ))
          .setInitialValues(initialValues)
          .setOnSuccess(() => {
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.components.checkpoints.fill-checkpoints-dialog.form.on-success')
            })

            this.meta.onSuccess && this.meta.onSuccess()
          })
      })
      .finally(() => this.loading = false)
  }

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}



















import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormBase from '@/shared/classes/form/form-base'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Attachment from '@/shared/modules/attachment/attachment.model'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import CheckpointService from '@/shared/modules/checkpoint/checkpoint.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import UploadCheckpointAttachmentsDialog from '@/company/components/checkpoints/UploadCheckpointAttachmentsDialog.vue'
import AttachmentsPreview from '@/company/components/attachments/AttachmentsPreview.vue'
import EditAttachmentDescriptionDialog from '@/company/components/attachments/EditAttachmentDescriptionDialog.vue'
import permissions from '@/shared/helpers/permissions.helper'

@Component({
  components: { AttachmentsPreview, UploadCheckpointAttachmentsDialog, DataTable },
  methods: { __ }
})
export default class EditCheckpointAttachments extends Vue {
  @Prop() meta!: any
  @Prop() form!: FormBase
  dialog: boolean = false
  attachments: Attachment[] = []
  table: DataTableBase = new DataTableBase()
    .setModel(Attachment)
    .setEndpoint(`company/${ this.company.slug }/checkpoints/${ this.meta.checkpoint.uuid }/attachments`)
    .setHasFilter(false)
    .setUpdateUrl(false)
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.imageWithText)
        .setKey('name')
        .setText(__('company.components.checkpoints.edit-checkpoint-attachments.table.headers.name'))
        .setMeta({
          imgKey: 'imgUrl',
          width: 40,
          height: 40,
          position: 'left',
        }),
      new DataTableHeader()
        .setKey('custom_properties.description')
        .setEntryKey('customProperties.description')
        .setText(__('company.components.checkpoints.edit-checkpoint-attachments.table.headers.description'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('custom_properties.author.full_name')
        .setEntryKey('customProperties.author.full_name')
        .setText(__('company.components.checkpoints.edit-checkpoint-attachments.table.headers.author')),
      new DataTableHeader()
        .setKey('created_at')
        .setEntryKey('createdAt')
        .setText(__('company.components.checkpoints.edit-checkpoint-attachments.table.headers.created-at'))
    ])
    .setOnChange(this.attachmentsChange)
    .setActions([
      new DataTableAction()
        .setIcon('mdi-eye')
        .setTitle(__('company.components.checkpoints.edit-checkpoint-attachments.table.actions.preview'))
        .setAction((attachment: Attachment) => {
          const tab: any = window.open(attachment.url, '_blank')
          tab.focus()
        }),
      new DataTableAction()
        .setIcon('mdi-download')
        .setTitle(__('company.components.checkpoints.edit-checkpoint-attachments.table.actions.download'))
        .setAction((attachment: Attachment) => {
          CheckpointService.downloadAttachment(this.company, this.meta.checkpoint, attachment)
            .then((response: any) => downloadFile(response, attachment.name))
        }),
      new DataTableAction()
        .setIcon('mdi-pencil')
        .setTitle(__('company.components.checklists.checklist-preview.attachments-table.actions.edit'))
        .setPermissions([permissions.attachments.update])
        .setComponent(EditAttachmentDescriptionDialog),
      new DeleteAction()
        .setAction((attachment: Attachment) => {
          CheckpointService.deleteAttachment(this.company, this.meta.checkpoint, attachment)
            .then(() => {
              this.table && this.table.removeItem(attachment)
              this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.success,
                message: __('company.components.checkpoints.edit-checkpoint-attachments.table.actions.delete.on-success'),
              })
            })
        })
    ])

  attachmentsChange(items: Attachment[]) {
    this.attachments = items
  }

  get uploadMeta(): any {
    return {
      checkpoint: this.meta.checkpoint,
      onCancel: () => {
        this.dialog = false
      },
      onSuccess: () => {
        this.dialog = false
        this.tableRef.refresh()
      }
    }
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}































































import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Group from '@/shared/modules/group/group.model'
import Project from '@/shared/modules/project/models/project.model'
import Checklist from '@/shared/modules/checklist/checklist.model'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import Attachment from '@/shared/modules/attachment/attachment.model'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import UploadCheckpointAttachmentsDialog from '@/company/components/checkpoints/UploadCheckpointAttachmentsDialog.vue'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import CheckpointService from '@/shared/modules/checkpoint/checkpoint.service'
import ChecklistService from '@/shared/modules/checklist/checklist.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import AddCheckpointDialog from '@/company/components/checkpoints/AddCheckpointDialog.vue'
import EditCheckpointDialog from '@/company/components/checkpoints/EditCheckpointDialog.vue'
import EditChecklistDialog from '@/company/components/checklists/EditChecklistDialog.vue'
import FillCheckpointsDialog from '@/company/components/checkpoints/FillCheckpointsDialog.vue'
import { CompanyRoutes } from '@/shared/router/company'
import Violation from '@/shared/modules/violation/violation.model'
import PageHeader from '@/admin/components/PageHeader.vue'
import can from '@/shared/helpers/can.helper'
import permissions from '@/shared/helpers/permissions.helper'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import moment from 'moment'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import AttachmentsPreview from '@/company/components/attachments/AttachmentsPreview.vue'
import EditAttachmentDescriptionDialog from '@/company/components/attachments/EditAttachmentDescriptionDialog.vue'

@Component({
  components: { AttachmentsPreview, Form, PageHeader, DataTable },
  methods: { __, can }
})
export default class ChecklistPreview extends Vue {
  permissions = permissions
  @Prop() activeGroup!: Group
  @Prop() checklist!: Checklist
  @Prop() project!: Project
  @Prop() goBackAction!: () => void
  attachments: Attachment[] = []

  checkpointsTable: DataTableBase = new DataTableBase()
    .setModel(Checkpoint)
    .setEndpoint(`company/${ this.company.slug }/checkpoints`)
    .setHasFilter(false)
    .setUpdateUrl(false)
    .setDoubleClick(this.openEditCheckpointDialog)
    .setIsSortable(can([permissions.company.checkpoints.reorder]))
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setTooltip((item: Checkpoint) => ({
          show: (!! item.violationUuid && item.statusUuid === Checkpoint.STATUS_VIOLATION),
          text: item.violationUuid && item.violation.uniqueId,
          item: item.violationUuid && item.violation,
          color: (item.violationUuid && item.violation.statusUuid === Violation.STATUS_CLOSED) ? 'grey' : 'orange',
          action: () => this.tooltipAction(item),
        }))
        .setText(__('company.components.checklists.checklist-preview.checkpoints-table.headers.name')),
      new DataTableHeader()
        .setKey('description')
        .setText(__('company.components.checklists.checklist-preview.checkpoints-table.headers.description')),
      new DataTableHeader()
        .setKey('end_at')
        .setEntryKey('endAt')
        .setText(__('company.components.checklists.checklist-preview.checkpoints-table.headers.end-at')),
      new DataTableHeader()
        .setKey('status.name')
        .setText(__('company.components.checklists.checklist-preview.checkpoints-table.headers.status')),
      new DataTableHeader()
        .setType(DataTableHeaderTypes.imageWithText)
        .setKey('attachments_count')
        .setEntryKey('attachmentsCount')
        .setText(__('company.components.checklists.checklist-preview.checkpoints-table.headers.attachments-count'))
        .setMeta({
          icon: 'mdi-file-image',
          width: 50,
          height: 30,
        })
        .setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-attachment mdi-rotate-90')
        .setTitle(__('company.components.checklists.checklist-preview.checkpoints-table.actions.upload'))
        .setPermissions([permissions.company.checkpoints.uploadAttachments])
        .setAction((checkpoint: Checkpoint) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: UploadCheckpointAttachmentsDialog,
            maxWidth: 600,
            meta: {
              checklist: this.checklist,
              checkpoint,
              onSuccess: (item: Checkpoint) => {
                this.$store.dispatch(GlobalActions.closeDialog)
                this.checkpointsTable.updateItem(item)
                this.attachmentsTableRef.refresh()
              }
            }
          })
        }),
      new EditAction()
        .setAction((checkpoint: Checkpoint) => this.openEditCheckpointDialog(checkpoint))
        .setPermissions([permissions.company.checkpoints.update]),
      new DeleteAction()
        .setPermissions([permissions.company.checkpoints.deleteAttachment])
        .setAction((checkpoint: Checkpoint) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('company.components.checklists.checklist-preview.checkpoints-table.actions.delete.title'),
              text: __('company.components.checklists.checklist-preview.checkpoints-table.actions.delete.text', { name: checkpoint.name }),
              onYes: () => {
                CheckpointService.destroy(this.company, checkpoint)
                  .then(() => {
                    this.checkpointsTable.removeItem(checkpoint)
                    this.$store.dispatch(GlobalActions.showSnackBar, {
                      type: SnackBarTypes.success,
                      message: __('company.components.checklists.checklist-preview.attachments-table.actions.delete.on-success')
                    })
                  })
              }
            }
          })
        })
    ])
    .setDefaultFilters([
      {
        operator: FilterOperators.equals,
        key: 'checklist_uuid',
        value: this.checklist && this.checklist.uuid,
      }
    ])

  attachmentsTable: DataTableBase = new DataTableBase()
    .setModel(Attachment)
    .setEndpoint(`company/${ this.company.slug }/checklists/${ this.checklist && this.checklist.uuid }/attachments`)
    .setHasFilter(false)
    .setUpdateUrl(false)
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.imageWithText)
        .setKey('name')
        .setText(__('company.components.checklists.checklist-preview.attachments-table.headers.name'))
        .setMeta({
          imgKey: 'imgUrl',
          width: 50,
          height: 50,
          position: 'left',
        }),
      new DataTableHeader()
        .setKey('custom_properties.description')
        .setEntryKey('customProperties.description')
        .setText(__('company.components.checklists.checklist-preview.attachments-table.headers.description'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('custom_properties.author.full_name')
        .setEntryKey('customProperties.author.full_name')
        .setText(__('company.components.checklists.checklist-preview.attachments-table.headers.author'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('created_at')
        .setEntryKey('createdAt')
        .setText(__('company.components.checklists.checklist-preview.attachments-table.headers.created-at')),
    ])
    .setOnChange(this.attachmentsChange)
    .setActions([
      new DataTableAction()
        .setIcon('mdi-download')
        .setTitle(__('company.components.checklists.checklist-preview.attachments-table.actions.download'))
        .setPermissions([permissions.company.checklists.downloadAttachment])
        .setAction((attachment: Attachment) => {
          ChecklistService.downloadAttachment(this.company, this.checklist, attachment)
            .then((response: any) => downloadFile(response, attachment.name))
        }),
      new DataTableAction()
        .setIcon('mdi-pencil')
        .setTitle(__('company.components.checklists.checklist-preview.attachments-table.actions.edit'))
        .setPermissions([permissions.attachments.update])
        .setComponent(EditAttachmentDescriptionDialog)
    ])

  form: FormBase = new FormBase()
      .setMethod(HttpMethod.PUT)
      .setEndpoint(`company/${ this.company.slug }/checklists/${ this.checklist && this.checklist.uuid }/update-control-user`)
      .setInitialValues({
        control_responsible_user_uuid: this.checklist && this.checklist.controlResponsibleUser && this.checklist.controlResponsibleUserUuid,
        control_responsible_user_assigned_at: this.checklist && this.checklist.controlResponsibleUser && this.checklist.controlResponsibleUserAssignedAt
      })
      .setFields([
        new SearchableField()
          .setKey('control_responsible_user_uuid')
          .setTitle(__('company.components.checklists.checklist-preview.control-responsible-user'))
          .loadItems({
            endpoint: `company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20
          })
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('control_responsible_user_assigned_at')
          .setTitle(__('company.components.checklists.checklist-preview.control-responsible-user-form.date'))
          .setMeta({
            min: moment().format('YYYY-MM-DD'),
          })
          .setSize(FieldSizes.half)
      ])
      .setOnSuccess(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('company.components.checklists.checklist-preview.control-responsible-user-form.on-success'),
        })

        this.$emit('checklist-updated')
      })

  created() {
    this.checkpointsTable.classes.tableCard = 'elevation-0'
    this.attachmentsTable.classes.tableCard = 'elevation-0'
  }

  tooltipAction(checkpoint: Checkpoint) {
    this.$router.push({
      name: CompanyRoutes.projectsShowTabViolations,
      params: {
        company: this.company.slug,
        uuid: this.project.uuid
      },
      query: {
        q: checkpoint.violation.uniqueId.split('-')[1]
      }
    })
  }

  openEditCheckpointDialog(checkpoint: Checkpoint): void {
    if (! can([permissions.company.checkpoints.update], false, checkpoint)) return
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditCheckpointDialog,
      persistent: true,
      maxWidth: 768,
      meta: {
        checkpoint,
        onSuccess: (item: Checkpoint) => {
          this.$store.dispatch(GlobalActions.closeDialog)
          this.checkpointsTable.updateItem(item)
          this.attachmentsTableRef.refresh()
        }
      }
    })
  }

  editChecklist(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditChecklistDialog,
      maxWidth: 500,
      persistent: true,
      meta: {
        checklist: this.checklist,
        onSuccess: (checklist: Checklist) => {
          this.$store.dispatch(GlobalActions.closeDialog)

          this.$emit('checklist-updated')
        }
      }
    })
  }

  addCheckpoint(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AddCheckpointDialog,
      maxWidth: 768,
      persistent: true,
      meta: {
        checklist: this.checklist,
        onSuccess: (checkpoint: Checkpoint) => {
          this.checkpointsTableRef.refresh()
          this.attachmentsTableRef.refresh()

          this.$store.dispatch(GlobalActions.closeDialog)
        }
      }
    })
  }

  fillCheckpoints(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: FillCheckpointsDialog,
      maxWidth: 786,
      persistent: true,
      meta: {
        project: this.project,
        checklist: this.checklist,
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.closeDialog)

          this.checkpointsTableRef.refresh()
          this.attachmentsTableRef.refresh()
        }
      }
    })
  }

  attachmentsChange(items: Attachment[]) {
    this.attachments = items
  }

  get checkpointsTableRef(): any {
    return this.$refs.checkpointsTable
  }

  get attachmentsTableRef(): any {
    return this.$refs.attachmentsTable
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}

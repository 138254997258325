import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'
import Attachment from '@/shared/modules/attachment/attachment.model'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IChecklistResponse from '@/shared/modules/checklist/checklist-response.interface'
import Checklist from '@/shared/modules/checklist/checklist.model'

export default class CheckpointService extends Service {
  static prefix = 'checkpoints'

  static indexByChecklist(company: Company, checklist: Checklist, perPage: number = 10): Promise<Checkpoint[]> {
    return this.get(`company/${ company.slug }/${ this.prefix }?filter-equals-checklist_uuid=${ checklist.uuid }&per_page=${ perPage }`)
      .then((response: IResponse<IChecklistResponse[]>) => response.data.data.map((item: IChecklistResponse) => new Checkpoint(item)))
  }

  static destroy(company: Company, checkpoint: Checkpoint): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ checkpoint.uuid }`)
  }

  static downloadAttachment(company: Company, checkpoint: Checkpoint, attachment: Attachment): Promise<any> {
    return this.get(`company/${ company.slug }/${ this.prefix }/${ checkpoint.uuid }/attachments/${ attachment.uuid }/download`, { responseType: 'blob' })
  }

  static deleteAttachment(company: Company, checkpoint: Checkpoint, attachment: Attachment): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ checkpoint.uuid }/attachments/${ attachment.uuid }`)
  }
}

















import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { FieldTypes } from '@/shared/components/form/field-types'
import Field from '@/shared/classes/form/field'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import ICheckpointResponse from '@/shared/modules/checkpoint/checkpoint-response.interface'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class UploadCheckpointAttachmentsDialog extends Vue {
  @Prop() meta!: any

  form: FormBase = new FormBase()
    .setModel(Checkpoint)
    .setEndpoint(`company/${ this.company.slug }/checkpoints/${ this.meta.checkpoint.uuid }/upload`)
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.dropzone)
        .setKey('attachments')
        .setTitle(__('company.components.checkpoints.upload-checkpoint-attachments-dialog.form.fields.attachments'))
        .setMeta({
          accept: 'image/*',
        }),
      new Field()
        .setKey('description')
        .setTitle(__('company.components.checkpoints.upload-checkpoint-attachments-dialog.form.fields.description')),
    ])
    .setSubmit({
      text: __('company.components.checkpoints.upload-checkpoint-attachments-dialog.form.submit')
    })
    .setOnSuccess((response: ICheckpointResponse, checkpoint: Checkpoint) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.checkpoints.upload-checkpoint-attachments-dialog.form.on-success'),
      })

      this.meta.onSuccess && this.meta.onSuccess(checkpoint)
    })

  closeDialog(): void {
    if (this.meta.onCancel) {
      this.meta.onCancel()
      return
    }
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}






















import { Component, Vue } from 'vue-property-decorator'
import Checklist from '@/shared/modules/checklist/checklist.model'
import ChecklistService from '@/shared/modules/checklist/checklist.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import ChecklistPreview from '@/company/components/checklists/ChecklistPreview.vue'
import { CompanyRoutes } from '@/shared/router/company'

@Component({
  components: { ChecklistPreview }
})
export default class ChecklistsShow extends Vue {
  checklist: Checklist | null = null

  created() {
    this.fetchChecklist()
  }

  async fetchChecklist() {
    this.checklist = await ChecklistService.show(this.company, this.$router.currentRoute.params.uuid)
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.checklistsIndex })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}

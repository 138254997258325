















import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormBase from '@/shared/classes/form/form-base'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import ICheckpointResponse from '@/shared/modules/checkpoint/checkpoint-response.interface'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class AddCheckpointDialog extends Vue {
  @Prop() meta!: any
  form: FormBase = new FormBase()
    .setModel(Checkpoint)
    .setEndpoint(`company/${ this.company.slug }/checkpoints`)
    .setFiles(true)
    .setValidate(true, { onlyRegionLangRequired: true })
    .setTranslatable(true)
    .setFields([
      new Field()
        .setKey('name')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.name'))
        .setTranslatable(true)
        .isRequired(),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('description')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.description'))
        .setTranslatable(true),
      new SelectField()
        .setKey('status_uuid')
        .setEntryKey('statusUuid')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.status'))
        .loadItems({ endpoint: `statuses/model/${ Checkpoint.ALIAS }`, value: 'uuid', title: 'name' })
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('end_at')
        .setEntryKey('endAt')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.end-at'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.dropzone)
        .setKey('attachments')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.attachments'))
        .setMeta({
          accept: 'image/*'
        }),
      new Field()
        .setKey('files_comment')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.attachments-comment'))
    ])
    .setInjectValues({
      checklist_uuid: this.meta.checklist.uuid,
    })
    .setInitialValues({
      statusUuid: Checkpoint.STATUS_DEFAULT,
    })
    .setSubmit({
      text: __('company.components.checkpoints.add-checkpoint-dialog.form.submit')
    })
    .setOnSuccess((response: ICheckpointResponse, checkpoint: Checkpoint) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.checkpoints.add-checkpoint-dialog.form.on-success'),
      })

      this.meta.onSuccess && this.meta.onSuccess(checkpoint)
    })

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}

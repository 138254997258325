














































import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import Attachment from '@/shared/modules/attachment/attachment.model'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { Form },
  methods: { __ }
})
export default class EditAttachmentDescriptionDialog extends Vue {
  @Prop() table!: DataTableBase
  @Prop() action!: DataTableAction
  @Prop() item!: Attachment

  dialog: boolean = false
  form: FormBase|null = null

  created() {
    this.form = new FormBase()
      .setMethod(HttpMethod.PUT)
      .setEndpoint(`attachments/${ this.item.uuid }`)
      .setModel(Attachment)
      .setFields([
        new Field()
          .setKey('description')
          .setTitle(__('company.components.attachments.edit-attachment-description-dialog.description'))
      ])
      .setInitialValues({
        description: this.item.description,
      })
      .setOnSuccess((data: any, attachment: Attachment) => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('company.components.attachments.edit-attachment-description-dialog.on-success'),
        })

        this.table.updateItem(attachment)

        this.onClose()
      })
  }

  onClose() {
    this.dialog = false
  }
}

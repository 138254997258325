















import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'
import ICheckpointResponse from '@/shared/modules/checkpoint/checkpoint-response.interface'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormGroup from '@/shared/classes/form/group'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import EditCheckpointAttachments from '@/company/components/checkpoints/EditCheckpointAttachments.vue'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import permissions from '@/shared/helpers/permissions.helper';

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class EditCheckpointDialog extends Vue {
  @Prop() meta!: any

  static GROUP_DETAILS = 'details'
  static GROUP_ATTACHMENTS = 'attachments'

  form: FormBase = new FormBase()
    .setUuid(this.meta.checkpoint.uuid)
    .setMethod(HttpMethod.PUT)
    .setModel(Checkpoint)
    .setEndpoint(`company/${ this.company.slug }/checkpoints`)
    .setValidate(true, { onlyRegionLangRequired: true })
    .setTranslatable(true)
    .setGroups([
      new FormGroup()
        .setKey(EditCheckpointDialog.GROUP_DETAILS),
      new FormGroup()
        .setKey(EditCheckpointDialog.GROUP_ATTACHMENTS)
        .setTitle(__('company.components.checkpoints.edit-checkpoint-dialog.form.groups.attachments'))
        .setComponent(EditCheckpointAttachments)
        .setComponentMeta({
          checkpoint: this.meta.checkpoint,
        })
    ])
    .setFields([
      new Field()
        .setKey('name')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.name'))
        .setGroup(EditCheckpointDialog.GROUP_DETAILS)
        .setTranslatable(true)
        .isRequired(),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('description')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.description'))
        .setGroup(EditCheckpointDialog.GROUP_DETAILS)
        .setTranslatable(true),
      new SelectField()
        .setKey('status_uuid')
        .setEntryKey('statusUuid')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.status'))
        .loadItems({ endpoint: `statuses/model/${ Checkpoint.ALIAS }`, value: 'uuid', title: 'name' })
        .setGroup(EditCheckpointDialog.GROUP_DETAILS)
        .setSize(FieldSizes.half)
        .setDisabled(! this.meta.checkpoint.hasDynamicPermissions([permissions.company.checkpoints.updateStatus])),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('end_at')
        .setEntryKey('endAt')
        .setTitle(__('company.components.checkpoints.add-checkpoint-dialog.form.fields.end-at'))
        .setGroup(EditCheckpointDialog.GROUP_DETAILS)
        .setSize(FieldSizes.half),
    ])
    .setOnSuccess((response: ICheckpointResponse, checkpoint: Checkpoint) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.checkpoints.edit-checkpoint-dialog.form.on-success')
      })

      this.meta.onSuccess && this.meta.onSuccess(checkpoint)
    })

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
